<template>
  <div class=" p-6 md:max-w-3/4 bg-white rounded-lg ml-auto mr-auto mb-4">
    <h1 class="font-bold text-lg text-center text-darkblue mb-3">
      New Stock
    </h1>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(SubmitStock)" class="">
        <div class=" mb-3 md:mb-6 justify-around flex flex-col md:flex-row">
          <div class="w-full md:w-5/12 mb-2">
            <label class="block md:text-xs" for="Stock name">Stock Name </label>
            <validation-provider
              rules="required|custom_text"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="stock.itemName"
                name="Stock name"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="w-full md:w-5/12 mb-2">
            <label class="block md:text-xs" for="Unit">
              Unit
            </label>
            <validation-provider rules="custom_text" v-slot="{ errors }">
              <input
                v-model="stock.unit"
                type="text"
                name="Unit"
                placeholder="E.g carton,pcs"
                class="border-2 md:text-xs w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class=" mb-3 md:mb-6  flex justify-center">
          <div class="w-full md:w-5/12   mb-2">
            <label class="block md:text-xs" for="Selling Price">
              Unit Selling Price(₦)
            </label>
            <validation-provider
              rules="required|numeric|min_value:1"
              v-slot="{ errors }"
            >
              <input
                name="Selling price"
                v-model="stock.unitSellingPrice"
                placeholder="Price of 1 unit of the stock"
                type="number"
                class="border-2 md:text-xs w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <button
          type="submit"
          class="text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full"
        >
          <svg
            v-if="loadingSpinner"
            class="inline animate-spin mr-2"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
          {{ $route.params.mode == "new" ? "Add Stock" : "Save Stock" }}
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations } from "vuex";

  export default {
    name: "ManageStock",
    props: {},
    components: { ValidationProvider, ValidationObserver },
    apollo: {
      stock: {
        query: gql`
          query stock($id: ID!) {
            stock(id: $id) {
              itemName
              unit
              unitSellingPrice
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        skip() {
          return this.$route.params.mode != "edit";
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSpinner = true;
            return;
          }

          this.loadingSpinner = false;
        },
      },
    },
    data() {
      return {
        loadingSpinner: false,
        stock: {
          itemName: "",
          unit: "",
          unitSellingPrice: "",
        },
      };
    },
    computed: {},
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      SubmitStock() {
        if (this.$route.params.mode != "edit") {
          this.CreateStock();
          return;
        }
        this.UpdateStock();
      },
      async CreateStock() {
        this.loadingSpinner = true;
        this.stock.unitSellingPrice = Number(this.stock.unitSellingPrice);
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($stock: StockInput!) {
                createStock(stock: $stock) {
                  _id
                  itemName
                  unit
                }
              }
            `,
            // Parameters
            variables: {
              stock: this.stock,
            },
          });

          this.loadingSpinner = false;
          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }
          this.$emit("show-alert", {
            type: "success",
            message: "Stock created successfully",
          });
          this.$router.push("/app/stock");
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating stock,check your network or contact your developer`,
          });
        }
      },
      async UpdateStock() {
        this.loadingSpinner = true;
        this.stock.unitSellingPrice = Number(this.stock.unitSellingPrice);

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!, $stock: StockInput!) {
                updateStock(id: $id, stock: $stock) {
                  _id
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
              stock: {
                itemName: this.stock.itemName,
                unit: this.stock.unit,
                unitSellingPrice: this.stock.unitSellingPrice,
              },
            },
          });

          this.loadingSpinner = false;
          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          if (!errors && !!data.updateStock._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Stock updated successfully`,
            });

            return this.$router.push("/app/stock");
          }
          this.$emit("show-alert", {
            type: "error",
            message: `Error updating stock,check your network or contact your developer`,
          });
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating stock,check your network or contact your developer`,
          });
        }
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Stock", pathName: "stock" },
        ...(this.$route.params.mode == "edit"
          ? [
              {
                displayName: "Single Stock",
                pathName: "singleStock",
                params: { id: this.$route.params.id },
              },
            ]
          : []),
        {
          displayName: "Manage Stock",
          pathName: "manageStock",
          params: { id: this.$route.params.id, mode: this.$route.params.mode },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
